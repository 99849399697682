import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { getAllUsers } from "../../services/users/users.service";
import {
  sendNotificationToALl,
  sendNotificationToUser,
} from "../../services/notification/notification.service";
import { sendEmail } from "../../services/email/email.service";

const Contact = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const handleGetUsers = async () => {
    setLoading(true);
    const res = await getAllUsers();
    console.log(res);
    setUsers(res);
    setLoading(false);
  };

  const handleSendEmail = async () => {
    setEmailLoading(true);
    const values = await form.validateFields(["email", "subject", "content"]);
    const body = {
      email: values.email,
      email_subject: values.subject,
      email_body: values.content,
    };
    const res = await sendEmail(body);
    if (res.success) {
      setEmailLoading(false);
      form.resetFields();
      message.success("E-Posta başarıyla gönderildi.");
    }
  };

  const handleSendNotification = async () => {
    setNotificationLoading(true);
    const values = await form.validateFields([
      "usersToSend",
      "notificationTitle",
      "notificationContent",
    ]);
    console.log("Selected Users: ", selectedUsers);
    console.log("VALUES: ", values);
    if (values.usersToSend.includes("all")) {
      const res = await sendNotificationToALl({
        title: values.notificationTitle,
        contents: values.notificationContent,
      });
      if (res?.success) {
        form.resetFields();
        message.success("Bildirim başarıyla gönderildi.");
      }
    } else {
      const res = await sendNotificationToUser({
        title: values.notificationTitle,
        contents: values.notificationContent,
        notify_id: selectedUsers,
      });

      if (res?.success) {
        form.resetFields();
        message.success("Bildirim başarıyla gönderildi.");
      }
    }
    setNotificationLoading(false);
  };

  useEffect(() => {
    handleGetUsers();
  }, []);
  console.log("USERS: ", users);
  return (
    <div>
      {loading ? (
        <div>
          <Spin />
        </div>
      ) : (
        <>
          <div>
            <h2>Bildirim Gönder</h2>
            <Form form={form} layout="vertical">
              <Form.Item
                label="Kullanıcılar"
                name="usersToSend"
                initialValue={["all"]}
              >
                <Select
                  mode="multiple"
                  placeholder="Kullanıcılar"
                  optionFilterProp={"children"}
                  showSearch
                  onChange={(value) => {
                    if (value.includes("all")) {
                      setSelectedUsers(["all"]);
                      form.setFieldValue("usersToSend", ["all"]);
                    } else {
                      setSelectedUsers(value);
                      form.setFieldValue("usersToSend", value);
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="all">Tüm Kullanıcılar</Select.Option>
                  {users.map((user: any) => (
                    <Select.Option key={user.notify_id} value={user.notify_id}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Başlık" name="notificationTitle">
                <Input placeholder="Başlık" />
              </Form.Item>
              <Form.Item label="İçerik" name="notificationContent">
                <Input.TextArea placeholder="İçerik" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  loading={notificationLoading}
                  onClick={async () => {
                    await handleSendNotification();
                  }}
                >
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div>
            <h2>E-Posta Gönder</h2>
            <Form form={form} layout="vertical">
              <Form.Item name="email" label="E-Posta">
                <Input type="email" placeholder="E-Posta Adresi" />
              </Form.Item>
              <Form.Item name="subject" label="Konu">
                <Input placeholder="Konu" />
              </Form.Item>
              <Form.Item name="content" label="İçerik">
                <Input.TextArea placeholder="İçerik" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  loading={emailLoading}
                  onClick={async () => {
                    await handleSendEmail();
                  }}
                >
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

export default Contact;

import React from "react";
import { STORAGE } from "../../storage";
import { Navigate, Outlet } from "react-router-dom";
import PageLayout from "../layout";

const ProtectedRoute = (props: any) => {
  const user = STORAGE.getAccessToken();

  if (!user) {
    return <Navigate to="/login" />;
  }
  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  );
};

export default ProtectedRoute;

import { request } from "../request";

export const sendNotificationToALl = async (data: {
  title: string;
  contents: string;
}) => {
  if (!data?.title || !data?.contents) {
    return;
  }
  const body = {
    title: {
      en: data.title,
      tr: data.title,
    },
    contents: {
      en: data.contents,
      tr: data.contents,
    },
  };
  const response = await request.post(
    `notifications/send-notification-to-all`,
    body,
  );
  if (response?.response?.id) {
    return { success: true, message: "Notification sent successfully" };
  } else {
    return { success: false, message: "Failed to send notification" };
  }
};

export const sendNotificationToUser = async (data: {
  notify_id: string[];
  title: string;
  contents: string;
}) => {
  if (data?.notify_id.length === 0 || !data?.title || !data?.contents) {
    return;
  }
  const body = {
    notify_id: data.notify_id,
    title: {
      en: data.title,
      tr: data.title,
    },
    contents: {
      en: data.contents,
      tr: data.contents,
    },
  };
  const response = await request.post(`notifications/send-notification`, body);
  if (response?.response?.id) {
    return { success: true, message: "Notification sent successfully" };
  } else {
    return { success: false, message: "Failed to send notification" };
  }
};

import React from "react";
import { Button, Space, Table } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useFirms } from "./firms.hook";
import dayjs from "dayjs";

const Firms = () => {
  const { firms, loading } = useFirms();

  return (
    <div>
      <h2>Firms</h2>
      <Table
        loading={loading}
        scroll={{ x: 500 }}
        dataSource={firms}
        columns={[
          {
            title: "Company Name",
            dataIndex: "companyName",
            key: "id",
            render: (companyName, record: any) => (
              <a href={`/firms/${record.slug}`}>
                {companyName || record.practiceName}
              </a>
            ),
          },
          {
            title: "Postal Code",
            dataIndex: "postCode",
            key: "postCode",
          },
          {
            title: "Category",
            dataIndex: "FirmCategories",
            key: "category",
            render: (categories) => (
              <span>
                {categories
                  ?.map((category: any) => category?.category?.title)
                  .join(", ") || ""}
              </span>
            ),
          },
          {
            title: "Subscription",
            dataIndex: "subscription",
            render: (subscription, company) => {
              const isSubscribed = company?.subscriptions.find((item: any) =>
                dayjs(item.endDate).isAfter(dayjs()),
              );
              return (
                <span>
                  {isSubscribed ? (
                    <CheckOutlined
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <CloseOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </span>
              );
            },
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (createdAt: string) => (
              <span>{dayjs(createdAt).format("DD/MM/YYYY HH:mm:ss")}</span>
            ),
          },
          {
            title: "Aksiyon",
            render: (value) => (
              <Space size={8}>
                <Button
                  href={`/firms/${value.slug}`}
                  type="primary"
                  onClick={() => {}}
                >
                  <EditOutlined />
                </Button>
                <Button type="primary" danger onClick={() => {}}>
                  <DeleteOutlined />
                </Button>
              </Space>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Firms;

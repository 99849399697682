import React, { FC } from "react";
import { Button, Layout, Menu, MenuProps } from "antd";
import styles from "./layout.module.scss";
import {
  AlertOutlined,
  FileSyncOutlined,
  HeartOutlined,
  MessageOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Logo from "../../assets/icons/logo-white.png";
import { useNavigate } from "react-router";
import { STORAGE } from "../../storage";
import { combineClassnames } from "../../utils/combineClassnames";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function createItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  createItem("Dashboard", "/dashboard", <PieChartOutlined />),
  createItem("Blog", "/blog", <HeartOutlined />),
  createItem("Users", "/users", <UserOutlined />),
  createItem("Contact", "/contact", <MessageOutlined />),
  createItem("Firms", "/firms", <AlertOutlined />),
  createItem("Content", "/content/management", <FileSyncOutlined />),
];
interface PageLayoutProps {
  children: React.ReactNode;
}
const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const [selectedMenuItemKey, setSelectedMenuItemKey] =
    React.useState<React.Key>("1");
  const [collapsed, setCollapsed] = React.useState(false);

  const navigate = useNavigate();
  return (
    <Layout hasSider>
      <Sider
        className={styles.sider}
        collapsible
        collapsedWidth={70}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        breakpoint="md"
      >
        <div
          className={combineClassnames(
            styles.logoContainer,
            collapsed ? styles.collapsed : "",
          )}
        >
          <img src={Logo} className={styles.logo} alt="logo" />
        </div>
        <Menu
          theme="dark"
          className={styles.menu}
          selectedKeys={[selectedMenuItemKey as string]}
          mode="inline"
          items={items}
          onClick={({ key }) => {
            setSelectedMenuItemKey(key);
            navigate(key);
          }}
        />
        <Button
          className={styles.logoutButton}
          type="primary"
          onClick={() => {
            STORAGE.removeRefreshToken();
            STORAGE.removeAccessToken();
            navigate("/login");
          }}
        >
          <PoweroffOutlined />
        </Button>
      </Sider>
      <Layout rootClassName={styles.layout}>
        <Header style={{ padding: 0, background: "transparent" }} />
        <Content
          style={{
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PageLayout;

import { request } from "../request";

export const getFirms = async () => {
  const response = request.get("firm");
  return response;
};

export const getFirmDetails = async (slug: string) => {
  const response = request.get(`firm/${slug}`);
  return response;
};

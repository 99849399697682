import React from "react";
import { Card, Tabs } from "antd";
import HomePageEdit from "../../components/HomePageEdit";

const ContentManagementPage = () => {
  return (
    <Card>
      <Tabs>
        <Tabs.TabPane key="1" tab="Home Page">
          <HomePageEdit />
        </Tabs.TabPane>
        <Tabs.TabPane key="1" tab="Join Page"></Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default ContentManagementPage;

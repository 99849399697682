import React from "react";
import { Table } from "antd";
import { useUsers } from "./users.hook";

const Users = () => {
  const { users, loading } = useUsers();
  return (
    <div>
      <Table
        loading={loading}
        scroll={{ x: 500 }}
        dataSource={users}
        columns={[
          {
            title: "Ad Soyad",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Telefon",
            dataIndex: "phone",
            key: "phone",
            render: (phone: string) => (
              <span>
                {phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "($1) $2 $3 $4")}
              </span>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Users;

import React from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import styles from "./login.module.scss";
import Logo from "../../assets/icons/logo.png";
import { login } from "../../services/auth/auth.service";
import { STORAGE } from "../../storage";
import { useNavigate } from "react-router";
import { selectIsLoading, setIsLoading } from "../../redux/features/appSlice";
import { setUser } from "../../redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const loading = useAppSelector(selectIsLoading);

  const dispatch = useAppDispatch();

  const onLogin = async () => {
    dispatch(setIsLoading(true));
    try {
      const response = await login(email.trim(), password.trim());
      dispatch(setIsLoading(false));
      if (response?.accessToken) {
        STORAGE.setAccessToken(response.accessToken);
        STORAGE.setRefreshToken(response.refreshToken);

        navigate("/dashboard");
      }

      if (response.userData) {
        dispatch(setUser(response.userData));
      }

      if (response.message) {
        dispatch(setIsLoading(false));
        message.error(response.message);
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <Form>
        <Row justify="center" gutter={[0, 16]}>
          <Col span={24}>
            <div className={styles.logoWrapper}>
              <img src={Logo} alt="logo" className={styles.logo} />
            </div>
          </Col>

          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  whitespace: true,
                  message: "Please enter your email address",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className={styles.input}
                placeholder="Email"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="password"
              rules={[
                {
                  type: "string",
                  required: true,
                  whitespace: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className={styles.input}
                placeholder="Password"
              />
            </Form.Item>
          </Col>

          <Button
            loading={loading}
            onClick={onLogin}
            block
            type="primary"
            size="large"
            className={styles.button}
          >
            Log in
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Login;

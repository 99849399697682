import { request } from "../request";

export const sendEmail = async (data: {
  email: string;
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(`email`, data);
  if (response.id) {
    return { success: true, message: "Email sent successfully" };
  } else {
    return { success: false, message: "Failed to send email" };
  }
};

import { useEffect, useState } from "react";
import { getFirms } from "../../services/firm/firm.service";

export const useFirms = () => {
  const [firms, setFirms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error] = useState<any>(null);

  const fetchFirms = async () => {
    setLoading(true);
    const result = await getFirms();
    console.log(result);
    setLoading(false);
    if (result) {
      setFirms(result);
    }
  };

  useEffect(() => {
    fetchFirms();
  }, []);

  return {
    firms,
    loading,
    error,
  };
};

import { getAllUsers } from "../../services/users/users.service";
import { useEffect, useState } from "react";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    const response = await getAllUsers();
    setUsers(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return { users, fetchUsers, loading };
};

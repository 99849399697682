import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Dashboard from "./pages/dashboard";
import Firms from "./pages/firms";
import Login from "./pages/login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Users from "./pages/users";
import Contact from "./pages/contact";
import Blog from "./pages/blog";
import NewBlogPost from "./pages/newBlogPost";
import EditFirm from "./pages/editFirm";
import ContentManagementPage from "./pages/content-management";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/firms" element={<Firms />} />
              <Route path="/firms/:slug" element={<EditFirm />} />
              <Route path="/users" element={<Users />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/new" element={<NewBlogPost />} />
              <Route path="/blog/edit/:slug" element={<NewBlogPost />} />
              <Route
                path="/content/management"
                element={<ContentManagementPage />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;

import { request } from "../request";
import { STORAGE } from "../../storage";

export const login = async (email: string, password: string) => {
  const response = await request.post("auth/admin/login", { email, password });
  return response;
};

export const logout = async () => {
  STORAGE.clear();
};

import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router";
import FirmProfile from "../../components/FirmProfile";
import { getFirmDetails } from "../../services/firm/firm.service";

const EditFirm = () => {
  const { slug } = useParams();
  const [company, setCompany] = React.useState<any>(null);

  const fetchCompany = useCallback(async () => {
    const result = await getFirmDetails(slug as string);
    if (result.firm) {
      console.log(result);
      setCompany(result.firm);
    }
  }, [slug]);

  useEffect(() => {
    if (slug) {
      // @ts-ignore
      fetchCompany();
    }
  }, [slug, fetchCompany]);
  return (
    <div>
      <FirmProfile company={company} />
    </div>
  );
};

export default EditFirm;

import React, { useCallback, useEffect, useState } from "react";
import styles from "./newBlogPost.module.css";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Upload,
} from "antd";
import "react-quill/dist/quill.snow.css";
import { CloseCircleFilled } from "@ant-design/icons";
import { request } from "../../services/request";
import { useNavigate, useParams } from "react-router";

type Post = {
  id?: number;
  popular?: boolean;
  slug?: string;
  coverImage?: string;
  author?: string;
  title: string;
  content: {
    image?: string;
    text?: string;
    title?: string;
  }[];
};

const NewBlogPost = () => {
  const [post, setPost] = useState<Post>({
    title: "",
    content: [],
  });
  const { slug } = useParams();
  const navigate = useNavigate();
  const [postImages, setPostImages] = useState<string[]>([]);
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const handleUploadProjectImage = async (image: any, index: number) => {
    const formData = new FormData();
    formData.append("file", image, image.name);
    const result = await request.post(
      "firm/project-image",
      formData,
      {
        "Content-Type": "multipart/form-data",
      },
      true,
    );

    if (result?.url) {
      setPostImages([...postImages, result.url]);
      const updatedContent = post.content;
      updatedContent[index].image = result.url;
      setPost({ ...post, content: updatedContent });

      message.success("Post image uploaded successfully.");
    } else {
      message.error("Failed to upload project image.");
    }
  };

  const fetchPostDetails = useCallback(async () => {
    const result = await request.get(`posts/${slug}`);
    if (result) {
      setPost(result.data);
      setCoverImage(result.data.coverImage);
    } else {
      message.error("Post not found");
      navigate("/");
    }
  }, [slug, navigate]);

  useEffect(() => {
    if (slug) {
      // @ts-ignore
      fetchPostDetails();
    }
  }, [slug, fetchPostDetails]);

  const handleUploadCoverImage = async (image: any) => {
    const formData = new FormData();
    formData.append("file", image, image.name);
    const result = await request.post(
      "firm/project-image",
      formData,
      {
        "Content-Type": "multipart/form-data",
      },
      true,
    );

    if (result?.url) {
      setCoverImage(result.url);
      message.success("Cover image uploaded successfully.");
    } else {
      message.error("Failed to upload cover image.");
    }
  };

  const handleSubmit = async () => {
    const doesExist = slug ? true : false;
    const id = post.id;
    if (doesExist) {
      const result = await request.put(`posts/${id}`, {
        title: post?.title,
        content: post.content,
        coverImage: coverImage,
      });
      if (result) {
        message.success("Post updated successfully.");
        navigate("/blog");
      } else {
        message.error("Failed to update post.");
      }
      return;
    }
    const result = await request.post("posts", {
      title: post?.title,
      content: post.content,
      coverImage: coverImage,
    });

    if (result) {
      message.success("Post created successfully.");
      navigate("/blog");
    } else {
      message.error("Failed to create post.");
    }
  };

  const addTextContent = () => {
    setPost({
      ...post,
      content: [
        ...post.content,
        {
          text: "",
        },
      ],
    });
  };

  const addImageContent = () => {
    setPost({
      ...post,
      content: [
        ...post.content,
        {
          image: "",
        },
      ],
    });
  };

  const addTitleContent = () => {
    setPost({
      ...post,
      content: [
        ...post?.content,
        {
          title: "",
        },
      ],
    });
  };
  return (
    <div className={styles.container}>
      <h1>Create Blog Post</h1>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Form className={styles.form}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  required
                  labelCol={{
                    span: 24,
                  }}
                  label={"Cover Image"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your cover image!",
                    },
                  ]}
                >
                  <Upload
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleUploadCoverImage(file);
                      return false;
                    }}
                  >
                    <Button>Upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  required
                  labelCol={{
                    span: 24,
                  }}
                  label={"Title"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your title!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      setPost({ ...post, title: e.target.value });
                    }}
                    value={post?.title}
                    placeholder="Title"
                  />
                </Form.Item>
              </Col>
              {post?.content.map((content, index) => {
                if (content.text !== undefined) {
                  return (
                    <Col span={24}>
                      <div className={styles.inputGroup}>
                        <Form.Item
                          key={index}
                          required
                          labelCol={{
                            span: 24,
                          }}
                          label={"Paragraph"}
                          rules={[
                            {
                              required: true,
                              message: "Please input your text!",
                            },
                          ]}
                          className={styles.textArea}
                        >
                          <Input.TextArea
                            value={content.text}
                            onChange={(e) => {
                              const updatedContent = post.content;
                              updatedContent[index].text = e.target.value;
                              setPost({ ...post, content: updatedContent });
                            }}
                            placeholder="Text"
                          />
                        </Form.Item>

                        <Button
                          type="primary"
                          danger
                          className={styles.deleteButton}
                          onClick={() => {
                            const updatedContent = post.content;
                            updatedContent.splice(index, 1);
                            setPost({ ...post, content: updatedContent });
                          }}
                          icon={<CloseCircleFilled />}
                        />
                      </div>
                    </Col>
                  );
                }
                if (content.image !== undefined) {
                  return (
                    <Col span={24}>
                      <div className={styles.inputGroup}>
                        <Form.Item
                          key={index}
                          required
                          labelCol={{
                            span: 24,
                          }}
                          label={"Image"}
                          rules={[
                            {
                              required: true,
                              message: "Please input your image!",
                            },
                          ]}
                          className={styles.textArea}
                        >
                          <Upload
                            accept="image/*"
                            showUploadList={false}
                            beforeUpload={(file) => {
                              handleUploadProjectImage(file, index);
                              return false;
                            }}
                          >
                            <Button>Upload</Button>
                          </Upload>
                        </Form.Item>
                        <Button
                          type="primary"
                          danger
                          className={styles.deleteButton}
                          onClick={() => {
                            const updatedContent = post.content;
                            updatedContent.splice(index, 1);
                            setPost({ ...post, content: updatedContent });
                          }}
                          icon={<CloseCircleFilled />}
                        />
                      </div>
                    </Col>
                  );
                }
                if (content?.title !== undefined) {
                  return (
                    <Col span={24}>
                      <div className={styles.inputGroup}>
                        <Form.Item
                          key={index}
                          required
                          labelCol={{
                            span: 24,
                          }}
                          layout={"vertical"}
                          label={"Title"}
                          rules={[
                            {
                              required: true,
                              message: "Please input your title!",
                            },
                          ]}
                          className={styles.textArea}
                        >
                          <Input
                            value={content?.title}
                            onChange={(e) => {
                              const updatedContent = post.content;
                              updatedContent[index].title = e.target.value;
                              setPost({ ...post, content: updatedContent });
                            }}
                            placeholder="Title"
                          />
                        </Form.Item>
                        <Button
                          type="primary"
                          danger
                          className={styles.deleteButton}
                          onClick={() => {
                            const updatedContent = post.content;
                            updatedContent.splice(index, 1);
                            setPost({ ...post, content: updatedContent });
                          }}
                          icon={<CloseCircleFilled />}
                        />
                      </div>
                    </Col>
                  );
                }
                return null;
              })}
              <Col span={12}>
                <Space size={16}>
                  <Button type="primary" onClick={addTextContent}>
                    Add Text Content
                  </Button>
                  <Button type="primary" onClick={addImageContent}>
                    Add Image Content
                  </Button>
                  <Button type="primary" onClick={addTitleContent}>
                    Add Title Content
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className={styles.previewWrapper}>
            <Card>
              <h2>Preview</h2>
              <div className={styles.preview}>
                {coverImage ? (
                  <div className={styles.coverWrapper}>
                    <img
                      className={styles.coverImage}
                      src={coverImage}
                      alt="cover"
                    />
                    <p className={styles.coverTitle}>{post?.title}</p>
                  </div>
                ) : (
                  <h1>{post?.title}</h1>
                )}

                {post.content.map((content, index) => {
                  if (content.text !== undefined) {
                    return <p key={index}>{content.text}</p>;
                  }
                  if (content.image !== undefined) {
                    return (
                      <img
                        className={styles.image}
                        key={index}
                        src={content.image}
                        alt="post-image"
                      />
                    );
                  }
                  if (content?.title !== undefined) {
                    return <h2 key={index}>{content?.title}</h2>;
                  }
                  return null;
                })}
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      <Row justify="start">
        <Col span={24}>
          <Button onClick={handleSubmit} type="primary" block>
            Publish Post
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewBlogPost;

export const STORAGE = {
  getUser: () => {
    return localStorage.getItem("@user");
  },
  setUser: (user: any) => {
    localStorage.setItem("@user", user);
  },
  removeUser: () => {
    localStorage.removeItem("@user");
  },
  getAccessToken: () => {
    return localStorage.getItem("@accessToken");
  },
  setAccessToken: (accessToken: any) => {
    localStorage.setItem("@accessToken", accessToken);
  },
  removeAccessToken: () => {
    localStorage.removeItem("@accessToken");
  },
  getRefreshToken: () => {
    return localStorage.getItem("@refreshToken");
  },
  setRefreshToken: (refreshToken: any) => {
    localStorage.setItem("@refreshToken", refreshToken);
  },
  removeRefreshToken: () => {
    localStorage.removeItem("@refreshToken");
  },
  clear: () => {
    localStorage.clear();
  },
  get: (key: any) => {
    return localStorage.getItem(key);
  },
  set: (key: any, value: any) => {
    localStorage.setItem(key, value);
  },
  remove: (key: any) => {
    localStorage.removeItem(key);
  },
};

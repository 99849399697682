import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

interface UserState {
  id: number;
  name: string;
  email: string;
  phone: string;
  age: number;
  created_at: string;
  updated_at: string;
  device_id: string;
  gender: string;
  height: number;
  isverified: boolean;
}

const initialState: UserState = {
  id: 0,
  name: "",
  email: "",
  phone: "",
  age: 0,
  created_at: "",
  updated_at: "",
  device_id: "",
  gender: "",
  height: 0,
  isverified: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return action.payload;
    },
    resetUser: () => {
      return initialState;
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
